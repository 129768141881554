import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';
import Glossary from '../FAQ-GLO/Glossary';
// export images for the slider
import image1 from '../../assets/images/lady-justice.jpg';
import image2 from '../../assets/images/family-cute.png';

//Export of images for the home
import logo from '../../assets/images/Logo-LegalBF-Vertical-CMYK-Blanco-01.png';
import serviceiImg1 from '../../assets/images/Legalbfimg1service1.png';
import serviceiImg2 from '../../assets/images/Legalbfimg1service2.png';
import serviceiImg3 from '../../assets/images/Legalbfimg1service3.png';

import './Home.css'

// Component
import Slider from './Slider';

const Home = () => {

    let topRef = useRef(null);

    const dataSlider = [{
        titulo: "LIQUIDEZ OPORTUNA?",
        subtitulo: "¿Tienes una sentencia contra el estado y necesitas",
        img: `${image1}`
    },
    {
        titulo: "ELIGE LEGAL BUSINESS",
        subtitulo: "Anticipa tus proyectos, invierte en ellos",
        img: `${image2}`
    }];

    // const [printStr, setprintStr] = useState();
    // const [perfilStatus, setPerfilStatus] = useState(false);

    // useEffect(() => {

    //     const writing = (str) => {

    //         let arrayWords = str.split('');
    //         let i = 0;
    //         let newArray = "";
    //         let interval = setInterval(function () {

    //             newArray += arrayWords[i];
    //             setprintStr(newArray);
    //             i++;

    //             if (i === arrayWords.length) {
    //                 clearInterval(interval);
    //                 setPerfilStatus(true);
    //             }
    //         }, 200);
    //     };

    //     writing("Legal Bussiness");
    // }, []);

    return (
        <div>
            <article className="containerSlider">
                <Slider stateSlider={dataSlider} />
            </article>

            <article className="containerReview">
                <section className="headerReview">
                    <img src={logo} alt="Logo Legalbf" />
                    {/* <div>
                        <h1>{printStr}</h1>
                        <p>Servicios financieros y jurídicos</p>
                    </div> */}
                </section>

                <p className="paragrapReview">Con más de 12 años de experiencia en el sector de compra de sentencias
                y conciliaciones, Legal Business es una compañía que gestiona la compra de derechos económicos derivados
                de sentencias y conciliaciones, debidamente ejecutorias y en firme contra entidades estatales, brindando
                a beneficiarios y abogados LIQUIDEZ OPORTUNA.    
                </p>

                <p className="paragrapReview">"Anticipa tus proyectos, invierte en ellos, elige…. Legal Business"</p>

                <section className="sectionBtnAboutMore">
                    <Link href='#top-menu' to='/quienesSomos' onClick={() => { topRef.current.scrollIntoView(true) }}><button>conocer mas<span className="icon-right-open"></span></button></Link>
                </section>
            </article>

            <h2 className="titleServices">Nuestros Servicios</h2>

            <article className="containerTargesServices">
                <Fade left>
                    <Link href='#top-menu' to='/comprasentencias' style={{ textDecoration: 'none'}} onClick={() => { topRef.current.scrollIntoView(true) }}>
                        <section className="targetServices">
                            <h3>Compra de sentencias</h3>
                            <p>Compramos tu sentencia, conciliación y/o laudos arbitrales falladas en contra del Estado.</p>
                        </section>
                    </Link>
                </Fade>
              
                <Fade right>
                    <Link href='#top-menu' to='/consultoriajuridica' style={{ textDecoration: 'none'}} onClick={() => { topRef.current.scrollIntoView(true) }}>
                        <section className="targetServices">
                            <h3>Consultoria y asesoria juridica</h3>
                            <p>Prestamos asesoría financiera y jurídica de manera efectiva y con calidad, entregando soluciones confiables en la negociación de tu sentencia.</p>
                        </section>
                    </Link>
                </Fade>
            </article>

            <article className="SectionContactBtn">
                <p>¿Que esperas para hacer parte de Legal Bussines?</p>
                <Link href='#top-menu' to='/contacto' onClick={() => { topRef.current.scrollIntoView(true) }}><button>Contactanos</button></Link>
            </article>

            <article className="articleServicesItemes">
                <section className="itemsServices">
                    <img src={serviceiImg1} alt="legalbfServiceitem2" />
                    <p>Contamos con un equipo de trabajo experimentado.</p>
                </section>

                <section className="itemsServices">
                    <img src={serviceiImg2} alt="legalbfServiceitem3" />
                    <p>Desde nuestros atributos entregamos a los clientes un servicio de calidad y con atención diferencial.</p>
                </section>

                <section className="itemsServices">
                    <img src={serviceiImg3} alt="legalbfServiceitem4" />
                    <p>Agilidad y eficiencia en el proceso de compra.</p>
                </section>
            </article>


{/* Deshabilitado por ahora */}
            {/* <article className="containerGlossary">
                <Glossary />
            </article> */}

        </div>
    );
};

export default Home;