
import emailjs from '@emailjs/browser';
import React, { useState,useRef } from 'react';
import './Contact.css';

const Contact = () => { 

    const form = useRef();

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setalertMessage] = useState();
    
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_yenpfg8','template_mbq5w8d',form.current,'fDu-fzyTKbXsdbkUQ')
        .then((result) => {
            if(result.status === 200) {
                setAlertStatus(true);
                setalertMessage('Mensaje enviado con exito');
            } else {
                setAlertStatus(false);
                setalertMessage('No se pudo enviar el mensaje');
            }  
        }, (error) => {
            console.log(error);
        });
        e.target.reset();

        setTimeout(function () {
            setAlertStatus(false);
        },4000)
    }


    return (
        <div>
            <article className="contactTitle">
                <h2>Contacto</h2>
                <p>Contáctanos a través de los diferentes canales que tenemos para ti, con el fin de darte la mejor atención!
                </p>
            </article>

            {alertStatus ? 
                <section className="alertForm">
                    <h1>{alertMessage}</h1>
                    <span className="icon-forward"></span>
                </section>
                :
                null
            }

            <article className="sectionContact">
                
                    <form ref={form} className="formContact" onSubmit={sendEmail}>
                        <section className="containerInput">
                            <input type="text" className="inputsForm" name='name_Client' required></input>
                            <label>Nombre</label>
                        </section>

                        <section className="containerInput">
                            <input type="mail" className="inputsForm" name='email_Client' required></input>
                            <label>Correo</label>
                        </section>

                        <section className="containerInput">
                            <input type="number" className="inputsForm" name='tel_Client' required></input>
                            <label>Telefono</label>
                        </section>

                        <section className="containerInput">
                            <input className="inputsForm" name='message_Client' required></input>
                            <label>Mensaje</label>
                        </section>

                        <section className="containerBtn">
                            <input className="btnSendMessage" type="submit" value='Enviar' />
                        </section>
                    </form>

                <section className="sectionInfoContact">
                    <h3>Información de contacto</h3>

                    <section className="sectionsInfo">
                        <h4><span className="icon-phone"></span>LLamanos ahora</h4>
                        <p>6017125950</p>
                        <p>3002135639</p>
                    </section>
                
                    <section className="sectionsInfo">
                        <h4><span className="icon-mail-alt"></span>Email</h4>
                        <p>info@legalbf.com</p>
                    </section>
                    <section className="sectionsInfo">
                        <h4><span className="icon-direction"></span>Dirección</h4>
                        <p>calle 26 # 69 - 76 Torre 3 Of 1501</p>
                        <p>Bogotá - Colombia</p>
                    </section>
                </section>
            </article>

            <article>
                <ul>

                </ul>
            </article>

            <p className="messageLocation">Encuentra nuestra ubicación <span className="icon-location"></span></p>

            <iframe className="iframeContact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.6319797416227!2d-74.10829098570643!3d4.659538443311661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b9986955555%3A0xd40b88cdb061433!2sLegal%20Business%20SAS!5e0!3m2!1ses!2sco!4v1668994431458!5m2!1ses!2sco"></iframe>

        </div>
    );
};

export default Contact;