
const Profile = ({showInfoTeam, setStateInfo}) => {

    return (
        <div className="backgroundModal">
        {showInfoTeam.map((data) => {
            return (
                <article key={data.id} className="modalTeam">
                <img src={data.urlImg} alt="legalbf_equipo"></img>
                <section className="infoModal">
                    <span className="icon-cancel-circled" onClick={() => setStateInfo(false)}></span>
                    <h4>{data.name}</h4>
                    <h3>{data.positionWorker}</h3>
                    <p>{data.description}</p>
                    <a className="emailModal" href={`mailto:${data.email}?Subject=Quisiera%20mas%20informacion`}>{data.email}</a>
                    <section className="contactInfo">
                        <a className="linkSocialMedia" href={data.linkedin} target="_blank"><span className="icon-linkedin-squared"></span></a>
                        <a className="linkSocialMedia" href="https://wa.link/d9nmi1" target="_blank"><span className="icon-whatsapp"></span></a>
                    </section>
                </section>
                
            </article>)
            })}
        </div>
    );
};

export default Profile;