import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Fade  from 'react-reveal/Fade';
import Bounce  from 'react-reveal/Bounce';

const Slider = ({ stateSlider }) => {
    let topRef = useRef(null);

    // Variables y Estados
    const [imagenNow, setImagenNow] = useState(0);
    const [show, setSow] = useState(true);
    const amount = stateSlider?.length;

    // Return prematuro para evitar errores
    if (!Array.isArray(stateSlider) || amount === 0) return;

    const nextImage = () => {
        setImagenNow(imagenNow === amount - 1 ? 0 : imagenNow + 1);
        setSow(!show);
    };

    const backImage = () => {
        setImagenNow(imagenNow === 0 ? amount - 1 : imagenNow - 1);
    };

    setTimeout(function(){
        nextImage();
    }, 10000);

    return (
        <>
            {/* <p className="btn-back" onClick={() => nextImage()}><span className="icon-left-open"></span>atras</p> */}
            {stateSlider.map((imagen, index) => {
            return (<div>
                <article className="sliderSection">
                    {imagenNow === index && (
                    <div key={index} className="slider">
                        <Bounce  top>
                            <img src={imagen.img} alt="imageSlide"></img>
                        </Bounce  >
                        <section className="infoSlider">
                            {show ? 
                                <Fade left>
                                    <p>{imagen.subtitulo}</p>
                                </Fade >
                                :
                                <Fade big>
                                    <p>{imagen.subtitulo}</p>
                                </Fade >
                            }
                            <h2>{imagen.titulo}</h2>
                            <div class="wrapper">
                                <Link href='#top-menu' to='/contacto' onClick={() => { topRef.current.scrollIntoView(true) }}>
                                    <button><span className="icon-right-hand">Adquiere tu propuesta</span></button>
                                </Link>
                            </div>
                        </section>
                    </div>
                    )}
                </article>
            </div>
            );
            })}
            {/* <p className="btn-next" onClick={() => backImage()}>siguiente<span className="icon-right-open"></span></p> */}
        </>

    );
};

export default Slider;