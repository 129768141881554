import logo from '../../assets/images/Logo-LegalBF-Vertical-CMYK-Blanco-01.png';

import './Footer.css';

const Footer = () => {
    return (
        <div>
            <article className="containerFooter">
                <section className="sectionFooter">
                    <img className="lofoFooter" src={logo} alt="" />
                </section>

                <section className="sectionFooter2">
                    <p>Torre 3 - oficina 1501</p>
                    <p>Bogotá - Colombia</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.6319797416227!2d-74.10829098570643!3d4.659538443311661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b9986955555%3A0xd40b88cdb061433!2sLegal%20Business%20SAS!5e0!3m2!1ses!2sco!4v1668994431458!5m2!1ses!2sco"></iframe>
                </section>

                <section className="sectionFooter3">
                    <label className="">Telefono</label>
                    <p>3002135639</p>

                    <label className="">Correo</label>
                    <p>info@legalbf.com</p>
                    <section className="socialMediaFooter">
                        <a className="linkSocialMedia" href="https://www.linkedin.com/company/legal-business-sas/" target="_blank"><span className="icon-linkedin-squared"></span></a>
                        <a className="linkSocialMedia" href="https://instagram.com/legal_business_sas?igshid=YmMyMTA2M2Y=" target="_blank"><span className="icon-instagram"></span></a>
                        {/* <a className="linkSocialMedia" href="" target="_blank"><span className="icon-twitter"></span></a> */}
                        <a className="linkSocialMedia" href="https://wa.link/d9nmi1" target="_blank"><span className="icon-whatsapp"></span></a>
                    </section>
                </section>
            </article>

            <p className="copy">&copy; 2022 Legal Bussines S.A.S</p>
        </div>
    );
};

export default Footer;