import Bounce from 'react-reveal/Bounce';

const JuridicalConsulting = () => {
    return (
        <div>
            <h2 className="titlePurchaseSentences">Consultoria y asesoria juridica</h2>

            <Bounce left><p className="subtitleConsul">¿Como podemos ayudarte?</p></Bounce>
            
            <article className="sectionInfoConsul">
                <p>Legal Business contrata sus servicios de consultoría y asesoramiento jurídico
                en la rama del derecho administrativo y de familia a través de un equipo de abogados
                y profesionales especializados.</p>

                <p>En el ámbito del derecho administrativo, en colaboración con la Organización Jurídica
                <span> James Hurtado López Ltda</span>, se han sustanciado procesos contra el Estado en los siguientes casos:</p>
            </article>

            <article className="sectionPartners">
                <p>ALIANZA CON LA ORGANIZACIÓN JAMES HURTADO LOPEZ</p>
                <a href="http://jameshurtadolopez.com.co/home/" alt="alianza" target="_blank"><button className="btnLinkPartners">James Hurtado<span className="icon-handshake-o"></span></button></a>
            </article>

        </div>
    );
};

export default JuridicalConsulting;