import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import "./fontello/css/fontello.css"
// import { useState, useEffect } from 'react';

// Components
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import PurchaseSentences from './Components/ServicesSection/purchaseSentences';
import JuridicalConsulting from './Components/ServicesSection/JuridicalConsulting';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

// styles
import './App.css';

function App() {

  let topRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [menu, setMenu] = useState(false);

  const scrollNav = () => {
    const posicion = window.pageYOffset;
    setScroll(posicion);
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollNav);
  }, [scroll]);

  const resetValues = () => {
    topRef.current.scrollIntoView(true);
    setMenu(false);
  }

  return (
    <div ref={topRef}>
      <Header />
      <Router>
        <nav id="top-menu" className={`headerMenu ${scroll > 120 ? 'scroll' : null}`}>
          <ul className={menu === true ? 'menuResponsive' : 'MenuOptions'}>
            <Link to="/" onClick={() => resetValues()} className=""><li>inicio</li></Link>
            <Link to="/quienesSomos" onClick={() => resetValues()} className=""><li>quienes somos</li></Link>
            <a>
              <li>servicios<span className="icon-down-open"></span>
                <ul class="children">
                  <Link to="/comprasentencias" onClick={() => resetValues()} className=""><li>compra de sentencias</li></Link>
                  <Link to="/consultoriajuridica" onClick={() => resetValues()} className=""><li>consultoria y asesoria juridica</li></Link>
                </ul>
              </li>
            </a>
            <Link to="/contacto" onClick={() => resetValues()} className=""><li>contacto</li></Link>
            <section className="buttonCloseSesion">
              <a href="http://www.clientslegalbf.com/" target="_blank"><button>clientes<span className="icon-users"></span></button></a>
            </section>
          </ul>
        </nav>

        <button className="icon-menu" onClick={() => setMenu(!menu)}></button>

        <article className="socialLateral">
          <section className="socialItemLateral">
            <a className="fondoItemSocial" href="https://www.linkedin.com/company/legal-business-sas/" target="_blank"><span className="icon-linkedin-squared"></span></a>
          </section>

          <section className="socialItemLateral">
            <a className="fondoItemSocial" href="https://www.instagram.com/legalbusiness.co?igsh=MW0ybHBxYm83MHBibQ==" target="_blank"><span className="icon-instagram"></span></a>
          </section>
        </article>

        <a href="https://wa.link/d9nmi1" target="_blank"><button className="btnWhatsapp icon-whatsapp"></button></a>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/quienesSomos' element={<AboutUs />} />
          <Route path='/comprasentencias' element={<PurchaseSentences />} />
          <Route path='/consultoriajuridica' element={<JuridicalConsulting />} />
          <Route path='/contacto' element={<Contact />} />
        </Routes>
      </Router>
      <Footer />
    </div>

  );
}
export default App;

