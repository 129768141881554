import indicar from '../../assets/images/imagenCompraSentencias.webp';
import escudoPoliciaLegalBf from '../../assets/images/policia.png';
import INVÍASLegalBf from '../../assets/images/INVÍASLegalBf.png';
import logoInpecLegalBf from '../../assets/images/logo-inpecLegalBf.png';
import MinDefensasLegalBf from '../../assets/images/MinDefensasLegalBf.png';
import CSJLegalBf from '../../assets/images/CSJ-01LegalBf.png';
import Fiscalía_GeneralLegalBf from '../../assets/images/fiscalia-removebg-preview.png';

import FAQ from '../FAQ-GLO/FAQ';

import './styleServices.css';

const purchaseSentences = () => {
    return (
        <div>
            <h2 className="titlePurchaseSentences">Compra de sentencias <span class="icon-balance-scale"></span></h2>

            <section className="video">
                <iframe src="https://www.youtube.com/embed/nCry9fbjgAw" title="Video Legal Business" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </section>

            <article className="Info">
                <img src={indicar} alt="indicar"/>
                <section className="textInfoPurchase">
                    <p>El objetivo de nuestros servicios es proporcionar <span>liquidez 
                    inmediata y oportuna</span> a los abogados y beneficiarios que tienen sentencias ejecutorias,
                    en firme y con turno de pago contra entidades del ESTADO.</p>
                    <p>Las clases de procesos legales que se pueden negociar son reparaciones directas, nulidades y restablecimiento del derecho y laudos arbitrales.
                    Las entidades pagadoras después de asignar turno de pago están demorando en promedio más de 2 años. Por lo tanto, ofrecemos una alternativa para obtener el pago anticipado de la entidad. 
                    </p>
                    <a href="/contacto"><button>Contactanos</button></a>
                </section>
            </article>


            <article className="containerInfoPurchase">
                <h3>¿Beneficios para las partes?</h3>
                <hr />

                <section className="ListBenefist">
                    <div className="targetsBenefits">
                        <p>Beneficios Demandante</p>
                        <ul>
                            <li><span className="icon-hammer"></span>Recibir recursos con anterioridad a la fecha de pago de la entidad, obteniendo así tranquilidad financiera y no estar a la expectativa de un pago prolongado</li>
                            <li><span className="icon-hammer"></span>Dar por terminado el proceso judicial</li>
                            <li><span className="icon-hammer"></span>Asesoría permanente desde el inicio de la negociación hasta el fin del desembolso</li>
                        </ul>
                    </div>

                    <div className="targetsBenefits">
                        <p>Beneficios Abogado</p>
                        <ul>
                            <li><span className="icon-hammer"></span>Recibir con anticipación los recursos por concepto de Honorarios</li>
                            <li><span className="icon-hammer"></span>Generar una disponibilidad de tiempo y liquidez</li>
                            <li><span className="icon-hammer"></span>Genera credibilidad a sus clientes al cumplir anticipadamente con el pago de la sentencia</li>
                        </ul>
                    </div>
                </section>
            </article>

            <h3 className="titleSteps">Conoce nuestro proceso para la compra de sentencias</h3>

            <article className="stepBuyingSentences">
                <section className="steps">
                    <p className="numberSteps"><span className="icon-right-hand"></span></p>

                    <div className="boxSteps">
                        <h4>Análisis y estudio de la viabilidad jurídica de la sentencia, conciliación o laudo arbitral</h4>
                        <p className="infoSteps">Etapa de elaboración de la propuesta económica, recolección de los documentos
                        pertinentes de la cuenta de cobro y anexos, entre otras actuaciones requeridas. Estudio de riesgos de la
                        contraparte.</p>
                    </div>
                </section>

                <section className="steps">
                    <div className="boxSteps">
                        <h4>Gestión documental</h4>
                        <p className="infoSteps">Elaboración de todos los documentos que hagan efectiva y valida la cesión de créditos
                        derivados de las sentencias judiciales. Los documentos se deben autenticar y se procederá a radicar la cesión
                        ante la entidad pagadora.</p>
                    </div>
                </section>

                <section className="steps">
                    <div className="boxSteps">
                        <h4>Notificación de la cesión de créditos por parte de la entidad pagadora </h4>
                        <p className="infoSteps">La entidad debe notificar la ACEPTACIÓN de la cesión de derechos económicos derivados de la
                        sentencia, conciliación o laudo arbitral. El tiempo de respuesta entre el momento en el que se radican los documentos y la
                        entidad emite la respuesta está entre 1 mes a 6 meses. (El tiempo de espera para la notificación depende de cada entidad).</p>
                    </div>
                </section>

                <section className="steps">
                    <div className="boxSteps">
                        <h4>Desembolso de la contraprestación pactada</h4>
                        <p className="infoSteps">Una vez Legal Business haya sido notificado por parte de la entidad de la ACEPTACIÓN de la cesión,
                        realizara el pago de la contraprestación pactada dentro de los 10 días siguientes a la notificación. </p>
                    </div>
                </section>
            </article>

            <article className="containerFaq">
                <FAQ />
            </article>

            <article className="sectionAllies">
                <h3 className="titleSteps">Entidades aptas para negociar</h3>
                <hr />
                <section className="sectionImgAllies">
                    <img src={escudoPoliciaLegalBf} alt="escudoPoliciaLegalBf.png" />
                    <img src={INVÍASLegalBf} alt="INVÍASLegalBf" />
                    <img src={logoInpecLegalBf} alt="logoInpecLegalBf" />
                    <img src={MinDefensasLegalBf} alt="MinDefensasLegalBf" />
                    <img src={CSJLegalBf} alt="CSJLegalBf" />
                    <img src={Fiscalía_GeneralLegalBf} alt="Fiscalía_GeneralLegalBf" />
                </section>
            </article>


        </div>
    );
};

export default purchaseSentences;