import { useState, useEffect} from 'react';

const FAQ = () => {

    const [showFAQ, setShowFaq] = useState();

    const showResponses = (activeValue) => {

        if (activeValue === showFAQ) {
            setShowFaq(0);
        } else {
            setShowFaq(activeValue);
        }
    }

    return (
        <div>
            <h2 className="titleFaq">Preguntas Frecuentes</h2>
            <hr className="titleFaqhr" />
            <section className="sectionFaq" onClick={ () =>showResponses(1)}>
                <h3>¿Podemos negociar una sentencia?<span className={`icon-down-open ${showFAQ === 1 ? 'openFAQ2' : 'openFAQ'}`}></span></h3>
                <p className={`infoFAQ  ${showFAQ === 1 ? 'infoFAQ' : 'NoShow'}`}>Actualmente, los derechos económicos derivados de las sentencias en contra del Estado forman
                parte del mercado de activos alternativos y se negocian como títulos valores. Los derechos 
                crediticios son susceptibles a cesión como lo señala el artículo de 1959 del Código Civil Colombiano.</p>
            </section>
            <section className="sectionFaq" onClick={ () =>showResponses(2)}>
                <h3>¿Cuánto tiempo se tarda en pagar una sentencia del estado?<span className={`icon-down-open ${showFAQ === 2 ? 'openFAQ2' : 'openFAQ'}`}></span></h3>
                <p className={`infoFAQ ${showFAQ === 2 ? 'infoFAQ' : 'NoShow'}`}>En la actualidad y dependiendo de cada entidad se tardan entre 2 años a 4 años.</p>
            </section>
            <section className="sectionFaq" onClick={ () =>showResponses(3)}>
                <h3>¿Qué tipos de sentencias compran?<span className={`icon-down-open ${showFAQ === 3 ? 'openFAQ2' : 'openFAQ'}`}></span></h3>
                <p className={`infoFAQ ${showFAQ === 3 ? 'infoFAQ' : 'NoShow'}`}>Las sentencias que negociamos son de Reparación Directa, Nulidad y Restablecimiento del Derecho y Laudos Arbitrales.</p>
            </section>
            <section className="sectionFaq" onClick={ () =>showResponses(4)}>
                <h3>¿Sentencias en contra de cuales entidades pagadoras se pueden negociar con LB?<span className={`icon-down-open ${showFAQ === 4 ? 'openFAQ2' : 'openFAQ'}`}></span></h3>
                <ul className={`infoFAQ ${showFAQ === 4 ? 'infoFAQ' : 'NoShow'}`}>Las sentencias, ejecutoriadas, en firme y con turno en contra de:
                    <li>Policía Nacional.</li>
                    <li>Ejército Nacional.</li>
                    <li>Armada Nacional.</li>
                    <li>Fuerza Aérea</li>
                    <li>Instituto Nacional Penitenciario y Carcelario "INPEC".</li>
                    <li>Fiscalía General de la Nación y Rama Judici.</li>
                    <li>Invías.</li>
                </ul>
            </section>
        </div>
    );
};

export default FAQ;